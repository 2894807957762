import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { BigHeadline } from "../components/styles/headlines"

const GalleryPage = ({ data }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [currentImg, setCurrentImg] = React.useState(null)

  let images = [
    {
      fileName: "image4",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image5",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image2",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image3",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image37",
      title: "Ein Bild eines Haarergebnisses",
      isBig: "true",
    },

    {
      fileName: "image35",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image36",
      title: "Ein Bild eines Haarergebnisses",
    },

    {
      fileName: "image29",
      title: "Ein Bild eines Haarergebnisses",
    },

    {
      fileName: "image7",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image8",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image9",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image30",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image10",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image11",
      title: "Ein Bild eines Haarergebnisses",
    },

    {
      fileName: "image14",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image15",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image12",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image13",
      title: "Ein Bild eines Haarergebnisses",
    },

    {
      fileName: "image16",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image19",
      title: "Ein Bild eines Haarergebnisses",
    },

    {
      fileName: "image17",
      title: "Ein Bild eines Haarergebnisses",
      isBig: "true",
    },
    {
      fileName: "image18",
      title: "Ein Bild eines Haarergebnisses",
    },

    {
      fileName: "image20",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image21",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image23",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image24",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image25",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image40",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image22",
      title: "Ein Bild eines Haarergebnisses",
      isBig: "true",
    },

    {
      fileName: "image31",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image32",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image33",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image34",
      title: "Ein Bild eines Haarergebnisses",
    },

    {
      fileName: "image38",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image39",
      title: "Ein Bild eines Haarergebnisses",
    },

    {
      fileName: "image41",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image42",
      title: "Ein Bild eines Haarergebnisses",
    },
    {
      fileName: "image1",
      title: "Ein Bild eines Haarergebnisses",
    },
  ]

  const toggleLightbox = index => {
    setIsOpen(true)
    setCurrentImg(index)
  }

  const prevImg = () => {
    if (currentImg === 0) {
      setCurrentImg(images.length - 1)
    } else {
      setCurrentImg(currentImg - 1)
    }
  }

  const nextImg = () => {
    if (currentImg === images.length - 1) {
      setCurrentImg(0)
    } else {
      setCurrentImg(currentImg + 1)
    }
  }

  return (
    <Layout>
      <Seo title="Galerie" />
      <section>
        <section className="container px-6  mx-auto grid md:grid-cols-2 gap-12 mt-60 mb-20">
          <BigHeadline>Looks</BigHeadline>
        </section>
        {/*`***************************************************************************************************************** Lightbox */}
        {isOpen ? (
          <div className="fixed h-screen w-screen top-0 left-0 bg-font bg-opacity-80 grid place-items-center z-20">
            {/* CLOSE */}
            <button
              className="absolute top-10 right-10 text-white z-50"
              onClick={() => setIsOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* NAV ARROWS */}
            <button
              className="absolute left-5 md:left-10 text-white y-1/2 z-50"
              onClick={prevImg}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
            </button>
            <button
              className="absolute right-5 md:right-10 text-white y-1/2 z-50"
              onClick={nextImg}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </button>
            {/* TITLE */}
            {/* <span className="block text-white absolute x-1/2 bottom-10 z-20">
              {images[currentImg].title}
            </span> */}
            {/* IMAGE */}
            <GatsbyImage
              style={{
                maxHeight: "750px",
                width: "100%",
              }}
              objectFit="contain"
              layout="fullWidth"
              backgroundColor="transparent"
              placeholder="blurred"
              alt={images[currentImg].title}
              image={getImage(
                data.allFile.edges.filter(
                  e => e.node.name === images[currentImg].fileName
                )[0].node
              )}
            />
          </div>
        ) : null}
        {/*`*********************************************************************************************************** Gallery Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-8 pb-8">
          {images.map((image, i) => {
            const imgSrc = data.allFile.edges.filter(
              e => e.node.name === image.fileName
            )[0].node

            return (
              <GatsbyImage
                layout="fullWidth"
                placeholder="blurred"
                alt={image.title}
                image={getImage(imgSrc)}
                key={i}
                className={image.isBig ? "lg:col-span-2" : null}
                onClick={() => toggleLightbox(i)}
              />
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query LookImages {
    allFile(filter: { relativeDirectory: { eq: "looks" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          name
        }
      }
    }
  }
`

export default GalleryPage
